import { FC } from "react";
import { SanityResponsiveImage } from "../../graphql-types";
import { desktopOnly, mobileOnly } from "../styles/utils.module.scss";
import Figure from "./Figure";

export interface ResponsiveImageProps {
  content: SanityResponsiveImage;
  fullQuality?: boolean;
}

const ResponsiveImage: FC<ResponsiveImageProps> = ({
  content,
  fullQuality,
}) => {
  return (
    <>
      {content.mobileImage && (
        <div className={mobileOnly}>
          <Figure value={content.mobileImage} fullQuality={fullQuality} />
        </div>
      )}
      {content.desktopImage && (
        <div className={content.mobileImage && desktopOnly}>
          <Figure value={content.desktopImage} fullQuality={fullQuality} />
        </div>
      )}
    </>
  );
};

export default ResponsiveImage;
